const version = 'api/v3'
const oldVersion = 'api/v1'
const updateVersion = 'api/v2'
const newVersion = 'api/v4'
const endPoints = {
  ME: `${version}/users/me`,
  AUTH: `${version}/auth`,
  CUSTOMERS: `${version}/customers`,
  ALL_BUSINESS: `${version}/businesses`,
  ALL_ACCOUNTS: `${version}/accounts`,
  CONTACTS: `${version}/contacts`,
  BANK_ACCOUNT_BALANCE: `${version}/check_balance_posn`,
  BANK_TRANSACTION: `${version}/transactions`,
  MONTHLY_STATEMENT: `${version}/transactions/monthly-statements`,
  RELATIONSHIP_TYPES: `${version}/get_relationship_types`,
  CREATE_BUSINESS: `${version}/businesses`,
  MFA_VERIFY: `${version}/mfa/send_verification_request`,
  VERIFY_CODE: `${version}/mfa/verify_code`,
  LINK_ACCOUNT: `${oldVersion}/accounts`,
  GET_CARDS_BY_ID: `${version}/cards`,
  GET_BUSINESS_ACCOUNT: `${version}/businesses`,
  GET_CONTACTS: `${version}/businesses`,
  // MAKE_PAY_VIA_CORE: `${version}/make_payment_via_CORE`,
  MAKE_PAY_VIA_CORE: `${oldVersion}/transactions/intrabank`,
  ACH_PAYMENTS: `${version}/ach_payments`,
  ACH_PAYMENTS_FOR_USER: `${version}/get_by_user`,
  MAKE_PAY_VIA_ACH: `${version}/new_ACH_outgoing_tp`,
  CREATE_PHYSICAL_CARD: `${version}/create_physical_card`,
  CREATE_VIRTUAL_CARD: `${version}/create_virtual_card`,
  STAFFS: `${version}/staffs`,
  MARQETA_CLIENT_ACCESS_TOKEN: `${version}/create_marqeta_client_access_token`,
  CARD_DETAIL: `${version}/cards/cvv`,
  SET_PIN: `${version}/cards`,
  CARD_TRANSACTION: `${version}/transactions`,
  CSV_TRANSACTION_EXPORT: `${version}/transactions/export`,
  GET_EXPENSE_CATEGORIES: `${version}/transactions/get_expense_categories`,
  SET_CARD_LIMIT: `${version}/setCardlimit`,
  GET_STAFF_ROLE: `${version}/get_role_list`,
  GET_BY_ROLE: `${version}/get_by_user`,
  PLAID_AUTH: `${version}/plaid_auth`,
  LINK_EXTERNAL_ACCOUNT_USER_ID: `${version}/link_external_account_user_id`,
  EXTERNAL_ACCOUNTS: `${newVersion}/external_accounts`,
  VERIFY_ACCOUNTS: `${newVersion}/verify_accounts`,
  CHECK_ELIGIBILITY: `${newVersion}/verify_accounts/check_eligibility`,
  PLAID_TOKEN_REQUEST: `${version}/plaid_token_request`,
  SKYSYSTEMZ_ROLE: `${version}/staffs/get_roles_from_skysystemz`,
  disclosures: `https://skysystemz.com/SkyFi_Privacy_Disclosures`,
  NEW_ACH_INCOMING: `${version}/new_ACH_incoming_tp`,
  LINK_EXTERNAL_ACCOUNT: `${version}/link_external_account`,
  FETCH_STAFF_CARDS: `${version}/fetch_cards_list`,
  FORGOT_PASSWORD: `${version}/forgot_password`,
  GET_KYC_STATUS: `${version}/get_kyc_status`,
  UPDATE_KYC: `${version}/update_kyc_to_pending`,
  GENERATE_IDENTITY_URL: `${version}/generate_identity_documents_url`,
  CERTIFICATE_OF_DEPOSIT: `${version}/users`,
  UPDATE_USER_CONCENT: `${updateVersion}/users/update_user_concent`,
};

export default endPoints

